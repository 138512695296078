import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from "react-oidc-context";
import reportWebVitals from './reportWebVitals';


const cognitoAuthConfig = {
    authority: `https://cognito-idp.us-east-1.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID!}`,
    client_id: process.env.REACT_APP_USER_POOL_CLIENT!,
    redirect_uri: `${process.env.REACT_APP_REDIRECT_URL}/auth/callback`,
    response_type: 'code',
    scope: 'aws.cognito.signin.user.admin email openid profile',
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <AuthProvider {...cognitoAuthConfig}>
            <App />
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
