import { createTheme } from '@mui/material/styles';

//#1b1e24

// Define your custom theme
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#33ffbb', // Set your primary color
    },
    secondary: {
      main: '#282c34', // Set your secondary color
    },
    background: {
      default: '#282c34',
    },
    // You can add other palette configurations like error, warning, etc.
  },
  typography: {
    fontFamily: 'Roboto, sans-serif', // Define your default font family
    // You can customize typography variants like h1, h2, body1, etc.
  },
});

export default theme;
