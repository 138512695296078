import React from 'react';
import { Typography, Box, Avatar, Fade, Divider, List, ListItem, ListItemText, useTheme } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import '../App.css';
import Footer from '../components/Footer';
import AnalysisDemo from './AnalysisWidget';
import VideoCard from './VideoCard';
import {
    OpeningHeader,
    HowItWorksList,
    OpeningHeaderInner,
    PrimaryText,
    UnderlineText,
    HowItWorksInnerLeft,
    BulletPointText,
    ChromeButton,
    HowItWorksInner,
    ThirdDivInner,
    BulletPoint,
    MainHeaderTextWeb,
    MainHeaderTextMobile,
    HowItWorks,
    SecondDiv,
    Benefits,
    BenefitsImage,
    HeaderText,
    BenefitsWrapper,
    Benefit,
    BenefitDescription,
    ThirdDiv,
    Text2Audio,
    Text2AudioInner,
    Text2AudioLeft,
} from './components';

const chromeClickEvent = () => {
    ReactGA.event({
        category: 'Chrome tool visit',
        action: 'Chrome tool visit',
    });
};

const Home = () => {
    const CHROME_LINK = 'https://chromewebstore.google.com/detail/synopify/cjambnblpmehbebdaedphlfglmlmedbn';

    const keyPoints = ['Concise and comprehensive breakdowns and summaries of any web page', 'Key facts and information about the company behind the website', 'Free to use'];
    const benefits = [
        {
            header: 'Time Saving',
            description: 'Save time by reading only the important information',
            img: '/benefits/color/clock.png',
        },
        {
            header: 'Extractable Insights',
            description: 'Be presented with the key information and insights from your web page',
            img: '/benefits/color/analysis.png',
        },
        {
            header: 'AI Assistance',
            description: 'Allow AI to read through large websites for you',
            img: '/benefits/color/brain.png',
        },
    ];

    const howItWorks = [
        `Using the URL and Title of the current browser tab to extract insights about the webpage's content and provide background information about the associated company`,
        'Summarise all content on the web page into a concise and comprehensive overview',
        'Present a list of key information around the website as well as a condensed paragraph summarising the content on the current page',
    ];
    const theme = useTheme();
    return (
        <Box className='first-div-class'>
            <Fade in={true} timeout={1000}>
                <div id='custom-gradient'>
                    <OpeningHeader>
                        <OpeningHeaderInner>
                            <HowItWorksInnerLeft>
                                <MainHeaderTextWeb variant='h3' sx={{ color: 'rgba(0, 0, 0, 0.8)', fontWeight: 'bold' }}>
                                    Your <PrimaryText>AI</PrimaryText> Powered Browser <UnderlineText>Assistant</UnderlineText>
                                </MainHeaderTextWeb>
                                <MainHeaderTextMobile variant='h4' sx={{ color: 'rgba(0, 0, 0, 0.8)', fontWeight: 'bold' }}>
                                    Your <PrimaryText>AI</PrimaryText> Powered Browser <UnderlineText>Assistant</UnderlineText>
                                </MainHeaderTextMobile>
                                <List dense sx={{ marginTop: '10px', marginBottom: '10px' }}>
                                    {keyPoints.map((point, idx) => (
                                        <ListItem key={`key-point-${idx + 1}`}>
                                            <BulletPoint>
                                                <CircleIcon color='primary' sx={{ fontSize: '12px' }} />
                                            </BulletPoint>
                                            <BulletPointText primaryTypographyProps={{ variant: 'subtitle1', fontWeight: idx === 2 ? 'bold' : 'regular' }}>{point}</BulletPointText>
                                        </ListItem>
                                    ))}
                                </List>
                                <Link to={CHROME_LINK} target='_blank'>
                                    <ChromeButton color='secondary' variant='contained' onClick={() => chromeClickEvent()}>
                                        <Avatar src='chrome-logo-white.png' />
                                        Add to Chrome
                                    </ChromeButton>
                                </Link>
                            </HowItWorksInnerLeft>
                            <VideoCard />
                        </OpeningHeaderInner>
                    </OpeningHeader>
                    <HowItWorks>
                        <HowItWorksInner>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '40px', padding: '20px' }}>
                                <HowItWorksList>
                                    <ListItem sx={{ color: 'rgba(0, 0, 0, 0.8)' }}>
                                        <ListItemText primaryTypographyProps={{ variant: 'h4', fontWeight: theme.typography.fontWeightBold, textAlign: 'center' }}>
                                            <UnderlineText>Summaries</UnderlineText> In Your <PrimaryText>Sidebar</PrimaryText>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primaryTypographyProps={{ variant: 'h5', fontWeight: theme.typography.fontWeightBold, textAlign: 'center' }}>How it works</ListItemText>
                                    </ListItem>
                                    <Divider />
                                    {howItWorks.map((point, index) => (
                                        <ListItem key={`how-it-works-point-${index + 1}`}>
                                            <BulletPoint>
                                                <CircleIcon color='secondary' sx={{ fontSize: '12px' }} />
                                            </BulletPoint>
                                            <BulletPointText primaryTypographyProps={{ variant: 'subtitle1' }}>{point}</BulletPointText>
                                        </ListItem>
                                    ))}
                                </HowItWorksList>
                                <AnalysisDemo />
                            </div>
                        </HowItWorksInner>
                    </HowItWorks>
                    <Text2Audio>
                        <Text2AudioInner>
                            <Text2AudioLeft>
                                <Typography variant='h4' sx={{ fontWeight: theme.typography.fontWeightBold, textAlign: 'center' }}>
                                    <UnderlineText>Convert</UnderlineText> <PrimaryText>Text</PrimaryText> To <PrimaryText>Speech</PrimaryText>
                                </Typography>
                                <Box>
                                    <Typography variant='h6' sx={{ textAlign: 'center', fontWeight: theme.typography.fontWeightLight, width: '80%', margin: 'auto' }}>
                                        Avoid the need to read through long paragraphs. Receive an audio narrative instead as Synopify will convert your web summaries into speech
                                    </Typography>
                                    <Typography variant='subtitle1' sx={{ textAlign: 'center', fontWeight: theme.typography.caption, width: '80%', margin: 'auto', marginTop: '20px' }}>
                                        Currently only supports English
                                    </Typography>
                                </Box>
                            </Text2AudioLeft>

                            <BenefitsImage src='robot_512.png' alt='robot' />
                        </Text2AudioInner>
                    </Text2Audio>
                </div>
            </Fade>

            <SecondDiv>
                <Benefits>
                    <div style={{ width: '80%', margin: 'auto' }}>
                        <HeaderText sx={{ textAlign: 'center' }} color='rgba(255, 255, 255, 0.9)' variant='h4'>
                            Benefits
                        </HeaderText>

                        <BenefitsWrapper>
                            {benefits.map((benefit) => (
                                <Benefit key={`benefit-${benefit.header}`}>
                                    <Avatar src={benefit.img} sx={{ background: 'none', margin: 'auto', width: 96, height: 96 }} />
                                    <Typography variant='subtitle1' sx={{ fontWeight: theme.typography.fontWeightBold, fontSize: '20px', color: 'rgba(255,255,255,0.8)' }}>
                                        {benefit.header}
                                    </Typography>
                                    <BenefitDescription variant='body1'>{benefit.description}</BenefitDescription>
                                </Benefit>
                            ))}
                        </BenefitsWrapper>
                    </div>
                </Benefits>
            </SecondDiv>
            <ThirdDiv>
                <ThirdDivInner>
                    <HeaderText color='rgba(0, 0, 0, 0.9)' variant='h4'>
                        Want to learn more?
                    </HeaderText>
                    <Typography variant='body1' sx={{ color: 'rgba(0, 0, 0, 0.7)', lineHeight: '2', fontWeight: theme.typography.fontWeightBold }}>
                        For any further information or enquiries please contact us to find out more
                    </Typography>
                    <Link to='/contact' target='_blank'>
                        <ChromeButton color='secondary' variant='contained' sx={{ fontSize: '24px' }}>
                            Contact us
                        </ChromeButton>
                    </Link>
                </ThirdDivInner>
            </ThirdDiv>
            <Footer />
        </Box>
    );
};

export default Home;
