import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Typography, useTheme, styled, Box, ListItemAvatar, Avatar, Button, ListItem, ListItemText } from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '75%',
    margin: 'auto',
    marginTop: theme.spacing(8),
}));

const StyledCard = styled(Card)<{ bgColor: string }>(({ theme, bgColor }) => ({
    minHeight: '50vh',
    padding: theme.spacing(4),
    width: '40%',
    backgroundColor: bgColor,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'flex-start',
    gap: theme.spacing(4),
}));

const Uninstall: React.FC = () => {
    const { palette, typography } = useTheme();
    const CHROME_LINK = 'https://chromewebstore.google.com/detail/synopify/cjambnblpmehbebdaedphlfglmlmedbn';
    return (
        <>
            <Container>
                <StyledCard bgColor='#f5faff' elevation={0}>
                    <Box>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: '#e8f4ff', fontWeight: typography.fontWeightBold, width: 56, height: 56 }} src='/crying.png' alt='crying' />
                            </ListItemAvatar>
                            <ListItemText primary='Thank you for trying Synopify' primaryTypographyProps={{ fontWeight: typography.fontWeightBold }} secondary={`We're sorry to see you go`} />
                        </ListItem>
                    </Box>

                    <Typography sx={{ color: palette.text.secondary }} variant='body2'>
                        We hope you had the opportunity to discover and make the most of what Synopify has to offer before you leave.
                    </Typography>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography sx={{ fontWeight: typography.fontWeightBold }} variant='body2'>
                            Ran out of free queries to quickly?
                        </Typography>
                        <Link to={CHROME_LINK} target='_blank'>
                            <Button sx={{ fontWeight: typography.fontWeightBold, textTransform: 'none', fontSize: '16px' }} variant='contained' color='secondary'>
                                Reinstall and get more free queries
                            </Button>
                        </Link>
                    </Box>
                </StyledCard>
            </Container>
        </>
    );
};

export default Uninstall;
