import React from 'react';
import { TextField, MenuItem, Box, Button, Paper, Typography, Divider, styled } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import { apiClient } from '../services/api.service';
import Footer from '../components/Footer';

const ContactWrapper = styled(Paper)(({ theme }) => ({
    width: '35%',
    margin: 'auto',
    padding: '40px',
    minHeight: '520px',
    marginTop: '40px',
    backgroundColor: '#121212',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '0px',
        padding: '10px',
    },
}));

const Contact = () => {
    const [selectedSubject, setSelectedSubject] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [email, setEmail] = React.useState('');

    const [submitting, isSubmitting] = React.useState(false);
    const [submitted, hasSubmitted] = React.useState(false);

    const [notification, setNotification] = React.useState('');

    const [emailError, setEmailError] = React.useState(false);
    const [subjectError, setSubjectError] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);

    const enquires = [
        { value: 'general', label: 'General' },
        { value: 'feedback', label: 'Feedback' },
        { value: 'support', label: 'Support' },
    ];

    const onChangeEmail = (e: any) => {
        setEmailError(false);
        setEmail(e.target.value);
    };

    const onChangeSubject = (e: any) => {
        setSubjectError(false);
        setSelectedSubject(e.target.value);
    };

    const onChangeMessage = (e: any) => {
        setMessageError(false);
        setMessage(e.target.value);
    };

    const submitMessage = () => {
        if (email === '') {
            setEmailError(true);
            return;
        }
        if (selectedSubject === '') {
            setSubjectError(true);
            return;
        }
        if (message === '') {
            setMessageError(true);
            return;
        }
        isSubmitting(true);
        apiClient
            .post('sso/contact', {
                Email: email,
                Subject: selectedSubject,
                Message: message,
            })
            .then(({ data }) => {
                setNotification(data.Message);
                hasSubmitted(true);
            })
            .finally(() => {
                isSubmitting(false);
                setMessage('');
                setEmail('');
                setSelectedSubject('');
            });
    };

    return (
        <>
            <div style={{ marginBottom: '40px' }}>
                <ContactWrapper elevation={3}>
                    {!submitted && (
                        <>
                            <Typography variant='h4' sx={{ textAlign: 'center', fontWeight: 'bold', color: 'rgba(255, 255, 255, 0.7)' }}>
                                Contact us
                            </Typography>
                            <Typography variant='subtitle1' sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'center' }}>
                                We would love to hear from you, please provide your feedback below!
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <TextField sx={{ backgroundColor: 'rgba(36, 36, 36, 0.7)' }} inputProps={{ sx: { color: 'white' } }} InputLabelProps={{ sx: { color: 'white' } }} error={emailError} label='Email' variant='filled' margin='normal' onChange={onChangeEmail} />
                                <TextField sx={{ backgroundColor: 'rgba(36, 36, 36, 0.7)' }} inputProps={{ sx: { color: 'white' } }} InputLabelProps={{ sx: { color: 'white' } }} select label='Type of Enquiry' error={subjectError} value={selectedSubject} onChange={onChangeSubject} variant='outlined' margin='normal'>
                                    {enquires.map((option) => (
                                        <MenuItem key={`message-enquire-${option.value}`} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField label='Message' sx={{ backgroundColor: 'rgba(36, 36, 36, 0.7)' }} inputProps={{ sx: { color: 'white' } }} InputLabelProps={{ sx: { color: 'white' } }} error={messageError} multiline rows={8} variant='filled' margin='normal' value={message} onChange={onChangeMessage} />
                                <Button variant='contained' sx={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.7)' }} onClick={submitMessage}>
                                    {!submitting ? 'Submit' : <ClipLoader color='rgba(0, 0, 0, 0.7)' />}
                                </Button>
                            </Box>
                        </>
                    )}
                    {submitted && (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '80px' }}>
                            <Typography variant='h4' sx={{ textAlign: 'center', fontWeight: 'bold', color: 'rgba(255, 255, 255, 0.7)' }}>
                                {notification}
                            </Typography>
                            <Button variant='contained' sx={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.7)' }} onClick={() => hasSubmitted(false)}>
                                Continue
                            </Button>
                        </div>
                    )}
                </ContactWrapper>
            </div>

            <Divider />
            <Footer />
        </>
    );
};

export default Contact;
