import React from 'react';
import { Menu, styled } from '@mui/material';

interface IProps {
    anchorEl: any;
    handleClose: () => any;
    children: React.ReactNode;
}

const StyledMenu = styled(Menu)(() => ({
    '.MuiMenu-paper': {
    },
}));

const DropdownMenu: React.FC<IProps> = ({ anchorEl, handleClose, children }) => {
    return (
        <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} sx={{ maxWidth: '90%' }}>
            {children}
        </StyledMenu>
    );
};

export default DropdownMenu;
