import React from 'react';
import { List, ListItem, ListItemText, ListItemButton, Typography, Box, styled, Divider, Collapse, Tabs, Tab, ListItemAvatar, Avatar } from '@mui/material';
import moment from 'moment';

interface IProps {
    history: any;
    loading: boolean;
}

const Container = styled(Box)(() => ({
    // minHeight: '40vh',
}));

const ListContainer = styled(List)(() => ({
    maxHeight: '40vh',
    minHeight: '40vh',
    overflow: 'auto',
}));

interface IPropsItem {
    historyItem: any;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const HistoryItem: React.FC<IPropsItem> = ({ historyItem }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [currentTab, setTab] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };
    return (
        <>
            <ListItem key={`history-item-${historyItem.Id}`}>
                <ListItemButton onClick={() => setOpen(!open)}>
                    <ListItemAvatar >
                        <Avatar alt="history-item-favicon" src={historyItem.Favicon} sx={{ width: 24, height: 24 }} />
                    </ListItemAvatar>
                    <ListItemText
                        primaryTypographyProps={{ variant: 'caption' }}
                        secondaryTypographyProps={{ variant: 'caption' }}
                        primary={historyItem.Title}
                        secondary={
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant='caption' color='text.secondary'>
                                    {`${historyItem.URL.split('').length >= 28 ? `${historyItem.URL.slice(0, 28)}......` : historyItem.URL}`}
                                </Typography>
                                <Typography variant='caption' color='text.secondary'>
                                    {moment(moment(historyItem.Timestamp).toLocaleString()).format('HH:mm MMM DD')}
                                </Typography>
                            </div>
                        }
                    />
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout='auto' unmountOnExit>
                <Tabs indicatorColor='secondary' textColor='secondary' sx={{ p: 0 }} variant='fullWidth' value={currentTab} onChange={handleChange}>
                    <Tab sx={{ textTransform: 'none', fontSize: '14px', p: 0 }} label='Page Summary' {...a11yProps(0)} />
                    <Tab sx={{ textTransform: 'none', fontSize: '14px', p: 0 }} {...a11yProps(1)} label='About URL' />
                </Tabs>
                <Divider />
                <CustomTabPanel value={currentTab} index={0}>
                    <Typography variant='caption' sx={{ lineHeight: 2 }}>
                        <List dense={true} sx={{ maxHeight: '95vh', overflow: 'auto', lineHeight: 2 }}>
                            {historyItem?.PageSummary.map((sentence: string, index: number) => (
                                <ListItem key={`summary-sentence-${index}`}>
                                    <ListItemText primary={sentence} primaryTypographyProps={{ variant: 'caption' }} />
                                </ListItem>
                            ))}
                        </List>
                    </Typography>
                </CustomTabPanel>
                <CustomTabPanel value={currentTab} index={1}>
                    <List sx={{ maxHeight: '95vh', overflow: 'auto', lineHeight: 2 }} dense={true}>
                        {historyItem?.WebsiteBreakdown?.map((sentence: string, index: number) => (
                            <ListItem key={`results-sentence-${index}`}>
                                <ListItemText primary={sentence} primaryTypographyProps={{ variant: 'caption' }} />
                            </ListItem>
                        ))}
                    </List>
                </CustomTabPanel>
                <Divider />
            </Collapse>
        </>
    );
};

const HistoryList: React.FC<IProps> = ({ history, loading }) => {
    return (
        <Container>
            {/* <Typography variant='subtitle1' sx={{ fontWeight: 'bold', padding: '12px' }}>
        History
      </Typography> */}
            {/* <Divider /> */}
            {loading && (
                <>
                    <Typography variant='subtitle2' color='text.secondary' sx={{ textAlign: 'center', padding: '20px' }}>
                        Fetching History...
                    </Typography>
                </>
            )}
            {!loading && (
                <ListContainer>
                    {history.map((hist: any) => (
                        <HistoryItem key={`history-item-${hist.Id}`} historyItem={hist} />
                    ))}
                </ListContainer>
            )}
        </Container>
    );
};

export default HistoryList;
