import { createContext } from 'react';

export interface IContext {
}

const AppContext = createContext<IContext>({

});

export default AppContext;
