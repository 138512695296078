import React from 'react';
import { List, ListItem, Typography, ListItemText, Divider, IconButton, styled, ListItemAvatar, Avatar, useTheme } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';
import '../App.css';

const FooterContainer = styled('footer')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '40px',
    width: '90%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0px',
    },
}));

const FooterButton = styled(IconButton)(() => ({
    backgroundColor: 'none',
}));

const FooterLinks = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: '10px',
        justifyContent: 'space-between',
    },
}));

const NavLinks = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '40px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: '10px',
        justifyContent: 'space-between',
    },
}));

const StyledItem = styled(ListItem)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const Footer = () => {
    const theme = useTheme();
    return (
        <div style={{ backgroundColor: '#1b1e24' }}>
            <FooterContainer>
                <FooterLinks>
                    <List>
                        <StyledItem>
                            <ListItemAvatar>
                                <Avatar src='/logo/updated/512.png' alt='icon_96' />
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5',
                                    sx: { fontWeight: 'bold', textTransform: 'none', textAlign: 'center' },
                                }}
                                sx={{ color: 'white' }}
                                primary='Synopify'
                            />
                        </StyledItem>
                        <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
                            <ListItem>
                                <Link to='https://www.linkedin.com/company/synopify' target='_blank'>
                                    <FooterButton size='medium'>
                                        <LinkedInIcon sx={{ color: '#a9a8a8' }} />
                                    </FooterButton>
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link to='https://www.instagram.com/synopify.ai' target='_blank'>
                                    <FooterButton size='medium'>
                                        <InstagramIcon sx={{ color: '#a9a8a8' }} />
                                    </FooterButton>
                                </Link>
                            </ListItem>
                        </ListItem>
                    </List>
                    <NavLinks>
                        <List>
                            <ListItem>
                                <ListItemText
                                    sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                                    primary='Products'
                                    primaryTypographyProps={{
                                        fontWeight: 'bold',
                                        variant: 'subtitle1',
                                        textAlign: 'center',
                                    }}
                                />
                            </ListItem>
                            <Link to='https://chromewebstore.google.com/detail/synopify/cjambnblpmehbebdaedphlfglmlmedbn' style={{ textDecoration: 'none' }} target='_blank'>
                                <ListItem>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            variant: 'overline',
                                            sx: { fontWeight: 'bold', textTransform: 'none', textAlign: 'center' },
                                        }}
                                        sx={{ color: 'white' }}
                                        primary='Browser extension'
                                    />
                                </ListItem>
                            </Link>
                        </List>
                        <List>
                            <ListItem>
                                <ListItemText
                                    sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                                    primary='Links'
                                    primaryTypographyProps={{
                                        fontWeight: 'bold',
                                        variant: 'subtitle1',
                                        textAlign: 'center',
                                    }}
                                />
                            </ListItem>
                            <Link to='/contact' style={{ textDecoration: 'none' }}>
                                <ListItem>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            variant: 'overline',
                                            sx: { fontWeight: 'bold', textTransform: 'none', textAlign: 'center' },
                                        }}
                                        sx={{ color: 'white' }}
                                        primary='Contact us'
                                    />
                                </ListItem>
                            </Link>

                            <Link to='/privacy' target='_blank' style={{ textDecoration: 'none' }}>
                                <ListItem>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            variant: 'overline',
                                            sx: { fontWeight: 'bold', textTransform: 'none', textAlign: 'center' },
                                        }}
                                        sx={{ color: 'white' }}
                                        primary='Privacy'
                                    />
                                </ListItem>
                            </Link>
                        </List>

                        <List>
                            <ListItem>
                                <ListItemText
                                    sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                                    primary='Contact'
                                    primaryTypographyProps={{
                                        fontWeight: 'bold',
                                        variant: 'subtitle1',
                                        textAlign: 'center',
                                    }}
                                />
                            </ListItem>
                            <Link to='mailto:contact@synopify.io' target='_blank' style={{ textDecoration: 'none' }}>
                                <ListItem>
                                    <ListItemText
                                        sx={{ color: 'white' }}
                                        primary='contact@synopify.io'
                                        primaryTypographyProps={{
                                            variant: 'overline',
                                            sx: { fontWeight: 'bold', textTransform: 'none', textAlign: 'center' },
                                        }}
                                    />
                                </ListItem>
                            </Link>
                        </List>
                    </NavLinks>
                </FooterLinks>
            </FooterContainer>
            <Divider />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#282c34' }}>
                <div></div>
                <Typography variant='overline' sx={{ textTransform: 'none', color: 'rgba(255, 255, 255, 0.7)' }}>
                    &copy; {new Date().getFullYear()} Synopify
                </Typography>
                <div></div>
            </div>
        </div>
    );
};

export default Footer;
