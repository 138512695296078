import React from 'react';
import { Button, Typography, styled, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    padding: theme.spacing(16),
    gap: theme.spacing(2),
    backgroundColor: 'white',
}));

const NotFound = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <>
            <Container>
                {/* <a href="https://www.flaticon.com/free-icons/page-not-found" title="page not found icons">Page not found icons created by kerismaker - Flaticon</a> */}
                <img src='/no-results.png' alt='no-results' />
                <Typography sx={{ color: theme.palette.text.secondary }} variant='body2'>
                    This path does not exist
                </Typography>
                <Button onClick={() => navigate('/')} sx={{ textTransform: 'none' }} color='secondary' variant='contained'>
                    Return home
                </Button>
            </Container>
            <Footer />
        </>
    );
};

export default NotFound;
