import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAuth } from 'react-oidc-context';
import { Dialog, Typography, styled, Button, Divider, ListItemAvatar, Avatar, DialogActions, DialogTitle, List, ListItem, ListItemText, Card, Tab, Tabs, useTheme, Box, DialogContent, InputBase } from '@mui/material';
import HistoryList from './HistoryList';
import moment from 'moment';

const API_URL = process.env.REACT_APP_API_URL!;


const CustomButton = styled(Button)(() => ({
    textTransform: 'none',
    fontSize: '12px',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '18px'
}))

interface IProps {
    open: boolean;
    handleClose: () => void;
}

interface DeleteProps extends IProps {
    confirm: () => Promise<void>;
}

type UserProfile = {
    aud: string;
    cognito: {
        groups?: string[];
    };
    cognito_username: string;
    email: string;
    email_verified: boolean;
    exp: number;
    family_name: string;
    given_name: string;
    iat: number;
    identities: {
        dateCreated: string;
        issuer: string | null;
        primary: string;
        providerName: string;
        providerType: string;
        userId: string;
    }[];
    iss: string;
    origin_jti: string;
    sub: string;
    token_use: string;
    picture?: string;
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#F3F6F9',
        border: '1px solid',
        borderColor: '#E0E3E7',
        fontSize: 14,
        width: '100%',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.secondary.main,
        },
    },
}));


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const ConfirmDelete: React.FC<DeleteProps> = ({ open, handleClose, confirm }) => {
    const [deleting, isDeleting] = React.useState<boolean>(false);

    const [deleteText, setText] = React.useState<string>('');

    const confirmDelete = async () => {
        isDeleting(true);
        try {
            await confirm();
            handleClose();
        } catch {
        } finally {
            isDeleting(false);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='lg'>
            <DialogTitle id='alert-dialog-title' variant='subtitle1'>
                Are you sure you want to delete your account
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 4 }}>
                    <img src='/exclamation.png' alt='warning' width={128} />
                    <BootstrapInput onChange={(e) => setText(e.target.value)} placeholder={`Type 'Delete' to confirm`} size="small" color='secondary' sx={{ width: '100%' }} />
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions>
                <LoadingButton disabled={deleteText.toLowerCase() !== 'delete'} loading={deleting} sx={{ textTransform: 'none' }} onClick={confirmDelete} color='error' variant='contained'>
                    Yes
                </LoadingButton>
                <Button sx={{ textTransform: 'none' }} disabled={deleting} onClick={handleClose} color='secondary' variant='contained'>
                    No
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const UserProfileComponent: React.FC<any> = () => {
    const auth = useAuth();

    const { user } = useOutletContext<{ user: UserProfile }>();

    const [openConfirmDelete, setConfirmDelete] = React.useState<boolean>(false);
    const [history, setHistory] = React.useState<any>([]);
    const [loadingHistory, setLoadingHistory] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (user) {
            const idToken = auth?.user?.id_token;
            setLoadingHistory(true);
            axios
                .get(`${API_URL}/chrome/user/history`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Bearer ${idToken}`,
                    },
                })
                .then((res) => setHistory(res.data))
                .finally(() => setLoadingHistory(false));
        } else {
            auth.signinRedirect()
        }
    }, [user, auth]);

    const navigate = useNavigate();

    const logout = () => {
        auth.signoutSilent();
        navigate('/');
    };

    const deleteAccount = async () => {
        const idToken = auth?.user?.id_token;
        try {
            await axios.delete(`${API_URL}/chrome/user`, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${idToken}`,
                },
            });
            logout();
        } catch { }
    };

    const [currentTab, setCurrentTab] = React.useState<number>(0);
    const theme = useTheme();

    return (
        <Card sx={{ height: '90vh', overflow: 'none' }} elevation={0}>
            <Box sx={{ width: '75%', margin: 'auto', marginTop: theme.spacing(4) }}>
                <Tabs
                    value={currentTab}
                    onChange={(_, newValue) => setCurrentTab(newValue)}
                    indicatorColor="primary"
                    textColor="inherit"
                    aria-label="account-tabs"
                >
                    <StyledTab value={0} label="My Account" />
                    <StyledTab value={1} label="History" />
                </Tabs>
            </Box>
            <ConfirmDelete confirm={deleteAccount} open={openConfirmDelete} handleClose={() => setConfirmDelete(false)} />
            <Card sx={{ width: '50%', margin: 'auto', border: `1px solid ${theme.palette.text.disabled}`, marginTop: theme.spacing(4) }} elevation={0}>
                <CustomTabPanel value={currentTab} index={0}>
                    {user && <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: '#e8f4ff', fontWeight: 'bold' }} src={user?.picture}>
                                    {user?.given_name?.charAt(0)}
                                    {user?.family_name?.charAt(0)}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${user?.given_name} ${user?.family_name}`} primaryTypographyProps={{ fontWeight: 'bold' }} secondary={user?.email} />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}>
                            <Typography sx={{ fontWeight: 'bold' }} variant='subtitle1'>
                                Authorization Provider
                            </Typography>
                            <Avatar alt={user?.identities[0]?.providerName} src={`/social-icons/${user?.identities[0]?.providerName.toLowerCase()}.png`} sx={{ bgcolor: 'white' }} />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}>
                            <ListItemText primary='Account Created' primaryTypographyProps={{ fontWeight: 'bold' }} secondary={moment(Number(user?.identities?.[0].dateCreated)).format('Do MMM YYYY')} />
                        </ListItem>


                        <Divider />
                        <ListItem sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}>
                            <ListItemText primary='User Id' primaryTypographyProps={{ fontWeight: 'bold' }} secondary={user?.sub} />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px' }}>
                                <CustomButton disableElevation color='secondary' variant='contained' onClick={logout}>
                                    Logout
                                </CustomButton>
                                <CustomButton onClick={() => setConfirmDelete(true)} color='error' variant='outlined'>
                                    Delete account
                                </CustomButton>
                            </div>
                        </ListItem>
                    </List>}
                </CustomTabPanel>
                <CustomTabPanel value={currentTab} index={1}>
                    <HistoryList loading={loadingHistory} history={history} />
                </CustomTabPanel>
            </Card>
        </Card>
    );
};

export default UserProfileComponent;
