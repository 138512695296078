import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "react-oidc-context";
import { Box, styled, Fade } from '@mui/material';

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh'
}))

const Callback = () => {
    const navigate = useNavigate();
    const auth = useAuth()

    React.useEffect(() => {
        if (!auth.isLoading && auth.isAuthenticated) {
            navigate('/user')
        } else if (!auth.isLoading && !auth.isAuthenticated) {
            auth.signinRedirect();
        }
    }, [auth, navigate]);

    return (
        <Container>
            <Fade in={true} timeout={1000}>
                <img src='/logo/updated/512.png' alt='logo' width={256} />
            </Fade>
        </Container>
    );
};

export default Callback;