import { Typography, Button, List, ListItemIcon, ListItemText, Box } from '@mui/material';
import { styled } from '@mui/material';

export const OpeningHeader = styled('div')(({ theme }) => ({
  width: '90%',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(0.5),
  },
}));

export const OpeningHeaderInner = styled('div')(({ theme }) => ({
  textAlign: 'left',
  width: '100%',
  margin: 'auto',
  padding: '40px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: '0px',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export const HowItWorksInnerLeft = styled('div')(({ theme }) => ({
  width: '80%',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
}));

export const HowItWorks = styled('div')(({ theme }) => ({
  textAlign: 'left',
  backgroundColor: 'white',
  padding: '60px',
  // background: `url('wave-haikei.svg') no-repeat center center`,
  // backgroundSize: 'cover',
  [theme.breakpoints.down('sm')]: {
    padding: '0px',
  },
}));

export const Text2Audio = styled('div')(({ theme }) => ({
  textAlign: 'left',
  background: `linear-gradient(to bottom right, white, #dde4f0)`,
  padding: '60px',
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
  },
}));

export const Text2AudioInner = styled(Box)(({ theme }) => ({
  width: '70%',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center',
  },
}));

export const Text2AudioLeft = styled(List)(({ theme }) => ({
  width: '70%',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: theme.spacing(12),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center',
    justifyContent: 'space-around',
    gap: theme.spacing(4),
  },
}));

export const ChromeDescriptionText = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.7)',
  lineHeight: '2',
  width: '100%',
  margin: 'auto',
  marginBottom: '60px',
  paddingRight: '20px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
    paddingRight: '0px',
  },
}));

export const BenefitsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '40px',
  marginTop: '40px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export const Benefits = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'left',
  width: '90%',
  margin: 'auto',
  padding: '60px',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
  },
}));

export const BenefitsImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const SecondDiv = styled('div')(({ theme }) => ({
  backgroundColor: '#1b1e24',
  color: 'white',
  width: '100%',
  padding: theme.spacing(0),
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

export const ThirdDiv = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  width: '100%',
  textAlign: 'center',
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

export const ThirdDivInner = styled('div')(({ theme }) => ({
  width: '70%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: 'auto',
  },
}));

export const PrimaryText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const UnderlineText = styled('span')(({ theme }) => ({
  textDecoration: 'underline',
  textDecorationColor: theme.palette.primary.main,
}));

export const HeaderText = styled(Typography)<{ color: string }>(({ theme, color }) => ({
  textDecoration: 'underline',
  textDecorationColor: theme.palette.primary.main,
  color,
  fontWeight: 'bold',
}));

export const ChromeButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '24px',
  color: 'white',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}));

export const Benefit = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  width: '40%',
  textAlign: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: 'auto',
    alignItems: 'center',
  },
}));

export const BenefitDescription = styled(Typography)(({ theme }) => ({
  width: '80%',
  margin: 'auto',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const HowItWorksList = styled(List)(({ theme }) => ({
  width: '70%',
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center',
  },
}));

export const MainHeaderTextWeb = styled(Typography)(({ theme }) => ({
  width: '80%',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const MainHeaderTextMobile = styled(Typography)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    width: '100%',
  },
}));

export const BulletPoint = styled(ListItemIcon)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const BulletPointText = styled(ListItemText)(({ theme }) => ({
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

export const HowItWorksInner = styled('div')(({ theme }) => ({
  width: '70%',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center',
  },
}));
